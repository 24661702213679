<template>
    <div class="main-box">
        <div class="con-box">
            <div class="title">
                <p>征信报告</p>
            </div>
            <div class="content-box">
                <a-table :columns="columns" :data-source="data">
                    <template #diagnosis="{ text }">
                        <a>{{ text }}</a>
                    </template>
                    <template #creditReporting="{ text }">
                        <a>{{ text }}</a>
                    </template>
                    <template #deepAnalysis="{ text }">
                        <a>{{ text }}</a>
                    </template>
                    <template #operation="{ record }">
                        <div v-if="data.length">
                            <a-button type="primary" @click="preview(record.key)">预览</a-button>
                            <a-button style="margin-left:20px" @click="download(record.key)" type="primary" >下载</a-button>
                            <a-button style="margin-left:20px" @click="print(record.key)" type="primary" >打印</a-button>
                        </div>
                    </template>
                </a-table>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent,ref} from 'vue';
    export default defineComponent({
        components: {},
        setup() {
            // 表格A企业资源
            const columns = [
                {
                    title: '序号',
                    dataIndex: 'key',
                    key: 'key',
                    align:'center',
                    width:60
                },
                {
                    title: '报告名称',
                    dataIndex: 'reportName',
                    key: 'reportName',
                    align:'center'
                },
                {
                    title: '生成时间',
                    dataIndex: 'generatedTime',
                    key: 'generatedTime',
                    align:'center'
                },
                {
                    title: '操作',
                    dataIndex: 'operation',
                    align:'center',
                    width:300,
                    slots: {
                        customRender: 'operation',
                    },
                },
            ];
            const data  = ref( [
                {
                    key: '1',
                    reportName:'黑龙江省范式智能技术有限公司企业征信分析报告',
                    generatedTime: '2020.10.20',
                },
                {
                    key: '2',
                    reportName:'黑龙江省范式智能技术有限公司企业征信分析报告',
                    generatedTime: '2020.10.20',
                },
                {
                    key: '3',
                    reportName:'黑龙江省范式智能技术有限公司企业征信分析报告',
                    generatedTime: '2020.10.20',
                },
            ]);
            const preview = key => {
                console.log(key);
            };
            const download = key => {
                console.log(key);
            };
            const print = key => {
                console.log(key);
            };
            return {
                columns,
                data,
                preview,
                download,
                print
            };
        },
    });
</script>
<style lang="less" scoped>
.content-box ::v-deep(.ant-table-thead){
    background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
    background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
    background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
    background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.content-box ::v-deep(.ant-table-thead th){
    background-color: transparent;
    color: @color-ff;
}

</style>
<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content-box{
            margin-top: 20px;
            font-size: 16px;
        }
    }
    .con-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */ 
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>